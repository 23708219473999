import {Route, Switch, useRouteMatch} from "react-router-dom";
import {Navigation} from "./navigation";
import {Gestalt} from "./methods_gestalt";
import {Art} from "./methods_art";
import {Cards} from "./methods_cards";
import React from "react";

export const Methods = (props) => {

    return (
        <article className="article">
            <ul className="Methods-list">
                <li className="Methods-list__item Methods-list__item_underline">
                    <a href="/methods-gestalt" className="Main-text">
                        <h3>Гешталь-терапія</h3>
                    </a>
                </li>
                <li className="Methods-list__item Methods-list__item_underline">
                    <a href="/methods-art" className="Main-text">
                        <h3>Арт-терапія</h3>
                    </a>
                </li>
                <li className="Methods-list__item Methods-list__item_underline">
                    <a href="/methods-cards" className="Main-text">
                        <h3>Метафоричні карти</h3>
                    </a>
                </li>

            </ul>
        </article>

    )
}