import {Article} from "./article";

export const Education = (props) => {
    return (
        <Article title="Моя освіта"
                 body="2000р – закінчила Львівський національний університет ім. І. Франка за спеціальністю «Біолог– мікробіолог. Викладач біології і хімії».

2010р – здобула другу вищу освіту в тому ж Львівському національному університеті ім. І. Франка. Спеціальність «Психолог. Викладач психології».

З 2016 року учасниця Буковинського проекту з групової психотерапії. Напрямок: гештальт- терапія.

В 2018 році розпочала навчання в Українському психотерапевтичному університеті, секція – гештальт-терапія.

Протягом 2017-2018 року брала участь в проекті з арт-терапії і отримала сертифікат СП №1542231 «Використання арт-терапії в роботі практичного психолога та соціального педагога». А також свідоцтва «Особливості використання казкотерапії у роботі практичного психолога та соціального педагога» і «Використання піскової терапії в роботі практичного психолога та соціального педагога».

З 2019 навчаюся в проекті «Корекція психосоматичних проявів методами арт-терапії».

У 2020 році завершила п'ятирічний Львівський навчальний проект з дитячої та юнацької психотерапії, отримала сертифікат Дитячо-юнацького психотерапевта. Завершила проект «Sandplay-терапія в роботі дитячого психотерапевта»."
        />
    )
}