import React from 'react';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';


const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 500 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 500, min: 0 },
        items: 1
    }
};


export const Features = () => {
    return (
        <section id='features' className='Methods text-center'>
            <div className='container'>
                <div className='col-md-10 col-md-offset-1 section-title'>
                    <h2>Методи роботи</h2>
                </div>
                <div className='row'>
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        infinite={true}
                        autoPlay={false}
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        className="Methods-wrapper col-xs-12 col-md-12 col-lg-12"

                    >
                        <article className="Method col-xs-12 col-md-4">
                            <a><img className="Method__picture" alt="gestalt" src="./img/puzzle2.jpg"/></a>
                            <h3 className="Method__title">
                                Гештальт-терапія
                            </h3>
                            <div className="Method__description">
                                <span className="Method__description-title">Головними принципами є:</span>
                                <ul type="circle" className="Method__description_list">
                                    <li>ідея жити "тут і тепер" - а не в минулому, чи в фантазіях про майбутнє</li>
                                    <li>усвідомленість - як перший крок до змін</li>
                                    <li>відповідальність за свій вибір</li>
                                </ul>
                            </div>
                            <a className="Method__find-out-more" href="/methods-gestalt">
                                Детальніше
                            </a>

                        </article>
                        <article className="Method col-xs-12 col-md-4">
                            <a><img className="Method__picture" alt="gestalt" src="./img/art.jpg"/></a>
                            <h3 className="Method__title">
                                Арт-терапія
                            </h3>
                            <div className="Method__description">
                                <span className="Method__description-title">Допомога людині використовуючи мистецтво. З-поміж різноманітних методів я
                                    використовую:</span>
                                <ul type="circle" className="Method__description_list">
                                    <li>ізотерапію</li>
                                    <li>пісочну терапію</li>
                                    <li>казкотерапію</li>
                                </ul>
                            </div>
                            <a className="Method__find-out-more" href="/methods-art">
                                Детальніше
                            </a>
                        </article>

                        <article className="Method col-xs-12 col-md-4">
                            <a><img className="Method__picture" alt="gestalt" src="./img/metaphoric-cards.jpg"/></a>
                            <h3 className="Method__title">
                                Метафоричні карти
                            </h3>
                            <div className="Method__description">
                                <span className="Method__description-title">
                                    Це універсальний психологічний інструмент, який подобається не тільки мені, а й клієнтам.
                                    Метафоричні асоціативні карти базуються на
                                    метафорі,
                                    що звертається до підсвідомості людини.
                                </span>
                            </div>
                            <a className="Method__find-out-more" href="/methods-cards">
                                Детальніше
                            </a>
                        </article>
                    </Carousel>
                </div>
            </div>
        </section>
    )
}
