export const About = () => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-5">
            <h2 className="about-text__title__xs">Про мене</h2>

            <img src="img/profile2-cropped.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 className="about-text__title__md">Про мене</h2>
              <p>Привіт, мене звати Ірина.  Я психолог, дитячо-юнацький психотерапевт, гештальт-терапевт в процесі сертифікації.</p>
              <p>Член Української спілки психотерапевтів.</p>
              <p>Практикую з 2010 року.</p>
              <p>Обожнюю свою роботу, вона  приносить задоволення, робить щасливою. Частинкою свого щастя охоче поділюся  з вами.</p>
              <p>Запрошую в цікаву подорож у світ власного пізнання. Цей шлях нелегкий, але по завершенні  отримаєте інший спосіб мислення, інше сприйняття (і нарешті прийняття) себе і світу.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
