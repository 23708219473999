import {Article} from "./article";

export const Cards = (props) => {
    return (
        <Article title="Про метафоричні карти"
                 body='Метафоричні асоціативні карти – це універсальний психологічний інструмент, який я із задоволенням використовую, і який так подобається клієнтам. Метод базується на метафорі, що звертається до підсвідомості людини.

Метафоричні асоціативні карти використовуються для діагностики, корекції, розвитку, розкриття творчих здібностей та коучингу.

Проективні карти – це різні набори (колоди), які, як і звичайні гральні карти, мають з одного боку «сорочку», а другий бік є змістовним. Але вони призначені для роботи з нашим внутрішнім світом, його особливостями, внутрішньою структурою та його зв’язками, контактами із світом зовнішнім.

Кожен клієнт сам надає певного змісту своїй карті. Тут отримує розвиток наша креативність і наше багатство ідей; сюди проектує себе наш внутрішній психічний світ.

Карти викликають певні асоціації, які є дуже особистими. Проективні карти можна порівняти з ненаписаною книгою.

Тож запрошую у світ непізнаного, пригод і відкриттів – у внутрішній світ кожного!'
        >
            Hi
        </Article>
    )
}