import React, {useState, useEffect} from "react";
import {Navigation} from "./components/navigation";
import {Header} from "./components/header";
import {Features} from "./components/features";
import {About} from "./components/about";
import {Services} from "./components/services";
import {Posts} from "./components/posts";
import {Education} from "./components/education";
import {Team} from "./components/Team";
import {Contact} from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import "./style/common.css";
import "./style/Layout.css";
import "./style/Additional-bar.css";
import "./style/About.css";
import "./style/Types.css";
import "./style/Slides.css";
import "./style/Methods.css";
import "./style/Content-wrapper.css";
import "./style/Appointment.css";
import "./style/Header.css";
import "./style/Logo.css";
import "./style/Menu.css";
import "./style/Main.css";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, useRouteMatch
} from "react-router-dom";
import {Methods} from "./components/methods";
import {Gestalt} from "./components/methods_gestalt";
import {Cards} from "./components/methods_cards";
import {Art} from "./components/methods_art";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});


export default function App() {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <Router>
            <div>

                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                    <Route path="/education">
                        <Navigation/>
                        <Education/>
                    </Route>
                    <Route path="/methods/">
                        <Navigation/>
                        <Methods/>
                    </Route>
                    <Route path="/methods-gestalt/">
                        <Navigation/>
                        <Gestalt/>
                    </Route>
                    <Route path="/methods-art/">
                        <Navigation/>
                        <Art/>
                    </Route>
                    <Route path="/methods-cards/">
                        <Navigation/>
                        <Cards/>
                    </Route>

                    <Route path="/">
                        <div>
                            <Navigation/>
                            <About/>
                            {/*<Header data={landingPageData.Header}/>*/}
                            <Services/>
                            <Features/>
                            {/*<Posts data={landingPageData.Gallery}/>*/}
                            <Contact/>
                        </div>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

