export const Navigation = () => {
  return (
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
                type='button'
                className='navbar-toggle collapsed'
                data-toggle='collapse'
                data-target='#bs-example-navbar-collapse-1'
                id="page-top"
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <a className='navbar-brand page-scroll' href='/#page-top' >
              <h1 className="Logo__name">Ірина Холод</h1>
              <span className="Logo__description">Психолог
                    у місті <strong>Львів</strong> та <strong>онлайн</strong></span>
            </a>{' '}

          </div>

          <div
              className='collapse navbar-collapse'
              id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>
              <li className="Menu__item nav-item"><a href="/">Головна</a></li>
              <li className="Menu__item nav-item"><a href="/education">Освіта</a></li>
              <li className="Menu__item nav-item dropdown">
                <a href="/methods" id="navbarDropdownMenuLink"
                   role="button"
                   data-mdb-toggle="dropdown"
                   aria-expanded="false"
                   className="nav-link dropdown-toggle"
                >Методи</a>
                <ul className="dropdown-menu Menu-dropdown" aria-labelledby="navbarDropdownMenuLink">
                  <li className="dropdown-item Menu-dropdown__item"><a href="/methods-gestalt">Гештальт-терапія</a></li>
                  <li className="dropdown-item Menu-dropdown__item"><a href="/methods-art">Арт-терапія</a></li>
                  <li className="dropdown-item Menu-dropdown__item"><a href="/methods-cards">Метафоричні карти</a></li>
                </ul>
              </li>
              <li className="Menu__item nav-item"><a href="/#contact">Контакти</a></li>
            </ul>
          </div>
        </div>
      </nav>
  )
}
