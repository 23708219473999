import {Article} from "./article";

export const Gestalt = (props) => {
    return (
        <Article title="Про гештальт-терапію"
                 body='Гештальт-терапію називають "мистецтвом контакту" - контакту з собою, з світом. Адже так важливо примирити "мушу" і "хочу", свої потреби з бажаннями інших.

Для відновлення внутрішнього балансу клієнтові пропонується зосередитися на актуальному - "фігурі", навчитися виділяти її з "фону".

Головними в процесі терапії є мобілізація власних ресурсів. Ціль - не змінити людину, а познайомитися з усіма своїми "Я", усвідомити їх і прийняти. Адже терапія - це шлях до себе, а не від себе.

Важливим також є усвідомлення меж власного "Я" і інших. "Свобода людини закінчується там, де починається свобода інших".'
        >
            Hi
        </Article>
    )
}