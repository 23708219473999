import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';

const responsive = {
    desktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 500 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 500, min: 0 },
        items: 1
    }
};



export const Services = (props) => {
    return (
        <div id='services ' className='text-center Types'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Працюю із запитами:</h2>
                </div>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={5000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    className="row"

                >

                    <div className="Type col-md-4">
                        <img src="./img/couple.jpg" alt="couple therapy" className="Type__picture"/>
                        <div className="Type__content service-desc">
                            <h3 className="Type__title">Стосунки в парі</h3>
                            <ul className="List-types">
                                <li className="List-types__item">відсутність парнера</li>
                                <li className="List-types__item">криза стосунків</li>
                                <li className="List-types__item">часті конфлікти</li>
                                <li className="List-types__item">розлучення</li>
                            </ul>
                        </div>
                    </div>
                    <div className="Type col-md-4">
                        <img src="./img/children-methods.jpg" alt="children therapy" className="Type__picture"/>
                        <div className="Type__content service-desc">
                            <h3 className="Type__title">Робота з дітьми і підлітками</h3>
                            <ul className="List-types">
                                <li className="List-types__item">тривожність</li>
                                <li className="List-types__item">страхи</li>
                                <li className="List-types__item">невпевненість</li>
                                <li className="List-types__item">поведінкові прояви</li>
                                <li className="List-types__item">агресивність</li>
                                <li className="List-types__item">психосоматичні та невротичні прояви</li>
                                <li className="List-types__item">проблеми комунікації з однолітками і батьками</li>
                                <li className="List-types__item">професійна самоідентифікація</li>
                            </ul>
                        </div>
                    </div>
                    <div className="Type col-md-4">
                        <img src="./img/lone.jpg" alt="personal therapy" className="Type__picture"/>
                        <div className="Type__content service-desc">
                            <h3 className="Type__title">Особистісні проблеми</h3>
                            <ul className="List-types">
                                <li className="List-types__item">невпевненість та низька самооцінка</li>
                                <li className="List-types__item">тривога</li>
                                <li className="List-types__item">втрата "смаку до життя"</li>
                                <li className="List-types__item">невдоволеність якістю життя
                                </li>
                                <li className="List-types__item">потреба в самореалізації</li>
                                <li className="List-types__item">горе, втрата близької людини</li>
                                <li className="List-types__item">стресові та депресивні стани</li>
                                <li className="List-types__item">панічні атаки</li>
                                <li className="List-types__item">психосоматичні захворювання</li>
                            </ul>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}
