import {useState} from 'react'
import emailjs from 'emailjs-com'

const initialState = {
    name: '',
    email: '',
    message: '',
}
export const Contact = (props) => {
    const [{name, email, message}, setState] = useState(initialState)

    const handleChange = (e) => {
        const {name, value} = e.target
        setState((prevState) => ({...prevState, [name]: value}))
    }
    const clearState = () => setState({...initialState})

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(name, email, message)
        let url = 'https://frozen-cove-83005.herokuapp.com/api/appointments';
        fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: JSON.stringify(
                {"name": name, "contact": email, "text": message}), // body data type must match "Content-Type" header
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                window.location.reload(false);
            });

    }
    return (
        <div id='contact'>
            <div className='container'>
                <div className='col-md-8'>
                    <div className='row'>
                        <div className='section-title'>
                            <h2>Отримати допомогу психолога</h2>
                            <p>
                                Запишіться на консультацію або задайте питання, яке вас цікавить
                            </p>
                        </div>
                        <form name='sentMessage' onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='text'
                                            id='name'
                                            name='name'
                                            className='form-control'
                                            placeholder="Ім'я"
                                            required
                                            onChange={handleChange}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <input
                                            type='email'
                                            id='email'
                                            name='email'
                                            className='form-control'
                                            placeholder='Емейл'
                                            required
                                            onChange={handleChange}
                                        />
                                        <p className='help-block text-danger'></p>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                  <textarea
                      name='message'
                      id='message'
                      className='form-control'
                      rows='4'
                      placeholder='Ваше питання'
                      required
                      onChange={handleChange}
                  ></textarea>
                                <p className='help-block text-danger'></p>
                            </div>
                            <div id='success'></div>
                            <button type='submit' className='btn btn-custom btn-lg'>
                                Надіслати
                            </button>
                        </form>
                    </div>
                </div>
                <div className='col-md-3 col-md-offset-1 contact-info'>
                    <div className='contact-item'>
                        <h3>Контакти</h3>
                        <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>

                            <a href="https://goo.gl/maps/D2AsughPvzHUbQbA9"><p>Львів, вул. К. Левицького</p></a>
                            <a href="https://goo.gl/maps/TDkBbVmHTmkg2cEx5"><p>Львів, вул. Я. Головацького</p></a>
                        </p>
                    </div>
                    <div className='contact-item'>
                        <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>
                            +380683979311
                        </p>
                    </div>
                    <div className='contact-item'>
                        <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>
                            irynaholod@gmail.com
                        </p>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='social'>
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/irakholodpsychologist">
                                        <i className='fa fa-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/kholod.iryna/">
                                        <i className='fa fa-instagram'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
