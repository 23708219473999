import {Article} from "./article";

export const Art = (props) => {
    return (
        <Article title="Про арт-терапію"
                 body='Арт-терапія – надзвичайно м’який, ненав’язливий, але дієвий метод для дітей і дорослих. Часто зустрічаюсь з упередженим ставленням, яке виражається у категоричному: «Я не вмію малювати!». Моя відповідь: «І це чудово!»

Для терапевтичного процесу важливо відпустити контроль і критичність – просто насолоджуватися творчістю. Адже, арт-терапевт ніколи не розглядає роботу клієнта з естетичної точки зору. Й арт-терапія далеко не обмежується малюванням. Це і робота з піском, глиною, казкотерапія – можна пробувати і шукати «свій» метод роботи.

Під час арт-терапії відбувається, так звана, сублімація – через художні візуальні образи і об’єкти відбувається взаємодія несвідомого зі свідомістю. А завдання терапевта – допомогти клієнту зрозуміти, що ж хоче «сказати» несвідоме.

Арт-терапія допомагає долати страхи, справитись із стресами, емоційним вигоранням, отримати ресурс і хороший настрій.

Ласкаво запрошую у звабливий світ арт-терапії.'
        >
            Hi
        </Article>
    )
}