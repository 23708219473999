export const Article = (props) => {
    return (
        <div className="container article">
            <div className="row ">
                <div className="col-md-12 mb50">
                    <article>
                        <div className="post-content">
                            <h2 className="article-title">{props.title}</h2>
                            <p className="article-body">{props.body}</p>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    )
}